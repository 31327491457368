.footer .container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .container .top{
    height: 500px;
    width: 95%;
    background: #2D96EE;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.footer .container .top .email-sub input{
    height: 50px;
    border: none;
    border-radius: 8px;
    width: 500px;
    padding: 5px;
}
.footer .container .top .email-sub .btn{
    background: #FF4948;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer .container .top .heading{
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
    color: white;
}
.footer .container .top .sub-heading{
    font-size: 37px;
    margin-bottom: 20px;
    font-weight: 600;
    color: white;
}
.footer .container .top .detail{
    font-size: 15px;
    margin-bottom: 50px;
    font-weight: 400;
    color: white;
}
.footer .bottom{
    margin-top: 125px;
}
.footer .bottom .container .row .col-12{
    font-size: 15px;
}
.footer .bottom .container .row .col-12 img{
    max-width: 220px;
    margin-bottom: 30px;
}
.footer .bottom .container .row .col-12 p{
    max-width: 380px;
    color: #000;
    font-weight: 600;
}
.footer .bottom .container .row .col-12 h5{
    font-size: 20px;
    font-weight: 700;
    color: #11142D;
    margin:0px 0px 30px 0px;
    padding-top: 25px;
}
.footer .bottom .container .bottom p{
    font-size: 20px;
    color: #000;
    font-weight: 600;
}
.footer .bottom .container .row .col-12 p a{
    text-decoration: none;
    color: inherit;
}
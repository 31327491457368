.blog{
    background: #E5E5E5;
    z-index: 0;
    position: relative;
}
.firstsec.blog{
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,7) 10%, rgba(0,0,0,0) 90%), url('../../../public/assets/blogpage/Image-6.png');
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
}
.blog_secondsec{
    margin: 80px 0;
    
}
.blog_secondsec .container .card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}
.blog_secondsec .container .card-container .blog-popular-card{
    max-height: 400px;
    width: 1050px;
    display: flex;
    flex-direction: row;
    background-color: #fff;
}
.blog_secondsec .container .card-container .blog-popular-card .left{
    width: 40%;
    background: #E2E2EA;
    display: flex;
}
.blog_secondsec .container .card-container .blog-popular-card .right{
    width: 60%;
    display: flex;
    padding: 40px 20px;
    flex-direction: column;
    padding-right: 130px;
}
.blog_secondsec .container .card-container .blog-popular-card .right .heading{
    font-size: 20px;
    color: #11142D;
    font-weight: 700;
}
.blog_secondsec .container .card-container .blog-popular-card .right .date{
    font-size: 12px;
    color: #9A9AB0;
    font-weight: 600;
}
.blog_secondsec .container .card-container .blog-popular-card .right .detail{
    font-size: 15px;
    color: #9A9AB0;
    font-weight: 600;
}
.blog_secondsec .container .card-container .blog-popular-card .right a{
    font-size: 15px;
    color: #0c6697;
    font-weight: 600;
}



.blog_thirdsec .container .card-container{
    padding: 50px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
.blog_thirdsec .container .card-container .blog-card{
    width: 350px;
    background-color: #fff;
}
.blog_thirdsec .container .card-container .blog-card .top{
    height: 350px;
    background: #E2E2EA;
    display: flex;
}
.blog_thirdsec .container .card-container .blog-card .bottom{
    display: flex;
    padding: 20px;
    flex-direction: column;
}
.blog_thirdsec .container .card-container .blog-card .bottom .heading{
    font-size: 20px;
    color: #11142D;
    font-weight: 700;
}
.blog_thirdsec .container .card-container .blog-card .bottom .date{
    font-size: 12px;
    color: #9A9AB0;
    font-weight: 600;
}
.blog_thirdsec .container .card-container .blog-card .bottom .detail{
    font-size: 15px;
    color: #9A9AB0;
    font-weight: 600;
}
.blog_thirdsec .container .card-container .blog-card .bottom a{
    font-size: 15px;
    color: #0c6697;
    font-weight: 600;
}
.blog .seventhsec {
    position: relative;
    z-index: 0;
}
.blog section.seventhsec.bg-white.py-md-5.py-2 > div.bg-box {
    width: 350px;
    height: 465px;
    right: 0;
    top: 0px;
}
.blog section.seventhsec.bg-white.py-md-5.py-2 > img.bg-vector.third {
    bottom: 130px;
    z-index: 2;
    left: 54px;
}
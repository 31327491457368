.aboutus{
    /* background: #E5E5E5; */
    z-index: 0;
    position: relative;
}
.firstsec.about{
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,7) 10%, rgba(0,0,0,0) 90%), url('../../../public/assets/aboutpage/Image7.png');
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
}
.about_secondsec .subheading{
    max-width: 450px;
    font-weight: 700;
    margin-bottom: 30px;
}
.about_secondsec .detail{
    margin-bottom: 75px;
}
.about_secondsec .mission{
    margin-top: 180px;
    margin-bottom: 50px;
}
.about_secondsec h5.heading{
    color: black;
    font-weight: 700;
    margin-bottom: 30px;
}

div.aboutus > section.about_secondsec.py-5{
    position: relative;
}
.fourthsec.about{
    margin-top: 30px;
    position: relative;
}
.fourthsec.about > .container > .row > .col-12 .subheading{
    max-width: 450px;
    font-weight: 700;
}
.fourthsec.about > .container > .row > .col-12 a{
    color: #0c6697;
}
.fifthsec.about{
    position: relative;
}
.fifthsec.about > .container > .head > .row > .col-12 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.fifthsec.about > .container > .head > .row > .col-12 h3{
    text-align: center;
}
.fifthsec.about > .container > .head > .row > .col-12 p{
    max-width: 700px;
    text-align: center;
}
.fifthsec.about > .container > .body > .img-card{
    width: 400px;
    height: 400px;
    background: #E2E2EA;
}
.fifthsec.about > .container > .body > .img-card:first-child{
    width: 400px;
    height: 400px;
    background-color: #0B528E;
}
.fifthsec.about > .container > .body > .img-card > .img-card-body h5{
    color: #11142D;
}
.fifthsec.about > .container > .body > .img-card > .img-card-body p{
    color: #999999;
}
div.aboutus > section.fifthsec.about > img{
    bottom: 140px;
    right: 0;
}
.sixthsec.about > .container > .head > .row > .col-12 h3{
    text-align: center;
}
.sixthsec.about > .container > .head > .row > .col-12 h5{
    text-align: center;
}
.sixthsec.about > .container > .body > .grid-card {
    background-color: #E2E2EA;
    text-align: center;
    padding: 20px;
    font-size: 30px;
    height: 250px;
}
.sixthsec.about > .container > .body {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
    padding: 10px;
    
  } 
  .item1 {
    grid-column: 1 / 3;
    grid-row: 1;
  }
  
  .item2 {
    grid-row: 2;
  }
  .item3 {
    grid-row: 2;
  }
  .sixthsec.about > .container > .body > .grid-card.item4 {
    grid-area: 1 / 3 / 4 / 5;
    height: 510px;
    
  }
  
  .item5 {
    grid-column: 1 / 3;
    grid-row: 3;
  }
  .item6 {
    grid-column: 3 / 5;
    grid-row: 3;
  }
  .sixthsec.about > .container > .body > .grid-card.item7 {
    grid-column: 1 / 5;
    grid-row: 4;
    height: 510px;
    
  }
div.aboutus > section.about_secondsec.py-5 > img{
    bottom: 0;
    left: 50px;
}
div.aboutus > section.fourthsec.about > div.bg-box{
    width: 400px;
    height: 580px;
    background-color: #E4EDFA;
    top: 0;
    z-index: -1;
    right: 0;
}
div.aboutus > section.sixthsec.about.bg-white.py-5.mb-0 > img.bg-vector.first{
    top: 115px;
    right: 40px;
}
div.aboutus > section.sixthsec.about.bg-white.py-5.mb-0{
    z-index: -2;
    position: relative;
}
div.aboutus > section.sixthsec.about.bg-white.py-5.mb-0 > img.bg-vector.second{
    bottom: 200px;
    left: 0;
}
div.aboutus > section.sixthsec.about.bg-white.py-5.mb-0 > div.bg-box{
    width: 280px;
    height: 500px;
    bottom: 590px;
}